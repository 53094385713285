import $ from 'jquery';

document.addEventListener('turbolinks:load', () => {
    $('.contentCategory').on('change', function () {
        const curr_url = window.location.origin + window.location.pathname;
        const form = $(this);
        const select = form.val();
        const new_url = curr_url + '?c=' + select;
        
        location.href = new_url;
    });
  }, false);
