// import Turbolinks from 'turbolinks'

document.addEventListener('turbolinks:load', () => {
    const nav = document.querySelector('.showNav');

    nav.addEventListener('click', function () {
        const nav = document.querySelector('#primary-navigation.overlay');
        nav.classList.toggle('active');
    });
  }, false);
