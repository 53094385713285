import Rails from '@rails/ujs'
import $ from 'jquery'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'

import 'alpinejs'
import 'tailwindcss/tailwind.css'

import '@fontsource/red-hat-display/400.css'
import '@fontsource/red-hat-display/500.css'
import '@fontsource/red-hat-display/700.css'

import 'controllers'
import './navigation'
import './filters'
// import './calendly'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()
