import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination, EffectFade, Controller as SwiperController } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination, EffectFade, SwiperController])

export default class extends Controller {
  static targets = ['next', 'prev', 'progressBar', 'mainContainer', 'titleContainer']

  connect() {
    let mainSwiper = new Swiper(this.mainContainerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      autoplay: {
        delay: 10000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },

      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },
    })

    let titleSwiper = new Swiper(this.titleContainerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      autoplay: {
        delay: 10000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
    })

    mainSwiper.controller.control = titleSwiper
    titleSwiper.controller.control = mainSwiper
  }
}
